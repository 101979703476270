<template>
  <div class="building-detail">
    <!-- 轮播图 -->
    <div class="detail-top">
      <van-swipe class="my-swipe" indicator-color="#fff">
        <van-swipe-item v-for="(item,index) in swipeList" :key="index" @click="goAlbum">
          <img :src="item" alt="" />
        </van-swipe-item>
        <van-swipe-item v-if="swipeList && swipeList.length == 0" @click="goAlbum">
          <img :src="require('../../assets/images/placeholder1.png')" alt="" />
        </van-swipe-item>
      </van-swipe>
      <div class="icon-box">
        <div class="icon-item" @click="goBack">
          <van-icon name="arrow-left" />
        </div>
        <div class="icon-right">
          <div class="icon-item" @click="collectClick" v-if="collect">
            <van-icon name="star" color="#E03E3F" />
          </div>
          <div class="icon-item" @click="collectClick" v-else>
            <van-icon name="star-o" />
          </div>

          <div class="icon-item">
            <van-icon name="share-o" @click="share = true" />
          </div>

          <div class="mcover" v-if="share" @click="share = false">
            <img :src="require('../../assets/images/share.png')">
          </div>

        </div>
      </div>
      <div class="browse"> {{updateTime}}{{ item01.showCount}}人浏览</div>
      <div class="sell-short" v-if="status == 2">
        <img src="../../assets/images/sale_all.png" alt="">
      </div>
    </div>
    <!-- 楼盘信息 -->
    <div class="detail-middle">
      <div class="middle-name">{{ item01.title }}</div>
      <div class="middle-msg">
        <van-grid :column-num="3" :border="false">
          <van-grid-item>
            <p>均价</p>
            <span>{{ item01.avPrice }}元/m²</span>
          </van-grid-item>
          <van-grid-item>
            <p>建筑面积</p>
            <span>{{ item01.buildArea }}m²</span>
          </van-grid-item>
          <van-grid-item @click="goCounter">
            <img src="../../assets/images/counter.png" alt="" />
            <p>房贷计算器</p>
          </van-grid-item>
        </van-grid>
      </div>
      <div class="middle-active">
        <div class="active-title">最新活动</div>
        <div class="active-content">{{ item01.concessional }}</div>
      </div>
      <div class="middle-content">
        <div class="middle-label">
          <span v-for="(item, index) in labelList" :key="index">{{
            item
          }}</span>
        </div>
        <ul>
          <li>
            <em>开 发 商</em>：
            <span>{{ item01.developer }}</span>
          </li>
          <li>
            <em>项目地址</em>：
            <span>{{ item01.address }}</span>
          </li>
          <li>
            <em>楼盘面积</em>：
            <span v-if="item01.totalArea">{{item01.totalArea}}m²</span>
          </li>
          <li>
            <em>户 数</em>：
            <span v-if="item01.houseCount">{{ item01.houseCount }}户</span>
          </li>
          <li>
            <em>容 积 率</em>：
            <span v-if="item01.plotRatio">{{ item01.plotRatio }}%</span>
          </li>
          <li v-if="showMsg">
            <em>绿 化 率</em>：
            <span v-if="item01.greenRate">{{ item01.greenRate }}%</span>
          </li>
          <li v-if="showMsg">
            <em>车 位</em>：
            <span>{{ item01.parking }}</span>
          </li>
          <li v-if="showMsg">
            <em>物 业 费</em>：
            <span v-if="item01.property">{{ item01.property }}元/m² 月</span>
          </li>
          <li v-if="showMsg">
            <em>预售许可</em>：
            <span>{{ item01.permits }}</span>
          </li>
          <li v-if="showMsg">
            <em>开盘时间</em>：
            <span>{{ item01.openDate != null?this.getTimes.timestampToDate(item01.openDate):"" }}</span>
          </li>
          <li v-if="showMsg">
            <em>交房时间</em>：
            <span>{{ item01.deliveryData != null?this.getTimes.timestampToDate(item01.deliveryData):"" }}</span>
          </li>
        </ul>
        <div class="show-msg" v-if="!showMsg" @click="showMsg = !showMsg">
          展开
          <van-icon name="arrow-down" />
        </div>
        <div class="show-msg" v-else @click="showMsg = !showMsg">
          收起
          <van-icon name="arrow-up" />
        </div>
      </div>
      <!-- <div class="middle-btn">
        <div class="btn-item" @click="showPricePopup">
          <img src="../../assets/images/cut-price.png" alt="" />
          <span>降价通知</span>
        </div>
        <div class="btn-item" @click="showOpeningPopup">
          <img src="../../assets/images/open.png" alt="" />
          <span>开盘通知</span>
        </div>
      </div> -->
    </div>
    <!-- 降价通知弹窗 -->
    <van-popup v-model="showPrice" round>
      <div class="price-box">
        <div class="price-logo">
          <img src="../../assets/images/low-price.png" alt="" />
        </div>
        <div class="price-title">降价通知</div>
        <div class="price-text">降价信息将会通过短信及推送消息通知您</div>
        <input type="text"  @input="nameFocus" v-model="name" placeholder="请输入姓名" />
        <input type="number" v-model="phone" placeholder="请输入手机号" />
        <van-checkbox v-model="checked" checked-color="#EC5B5B" icon-size="16px">我已阅读并同意<span @click.stop="showAgreePopup">《服务协议》</span></van-checkbox>
        <div class="price-btn">
          <div class="btn-cancel" @click="closePricePopup">取消</div>
          <div class="btn-sure" @click="getMessage(0)">确定</div>
        </div>
      </div>
    </van-popup>
    <!-- 开盘通知弹窗 -->
    <van-popup v-model="showOpening" round>
      <div class="opening-box">
        <div class="price-title">申请关注</div>

        <input type="text"  @input="nameFocus" v-model="name" placeholder="请输入姓名" />
        <input type="number" v-model="phone" placeholder="请输入手机号" />
        <div class="price-text opening-text">
          楼盘开盘及变价信息将第一时间通知你
        </div>
        <div class="price-btn">
          <div class="btn-cancel" @click="closeOpeningPopup">取消</div>
          <div class="btn-sure" @click="getMessage(1)">确定</div>
        </div>
      </div>
    </van-popup>
    <!-- 服务协议弹窗 -->
    <van-popup v-model="showAgree" class="agree" :overlay-style="{ background: '#fff' }">
      <div class="agree-content" v-html="$xss(agree)"></div>
    </van-popup>
    <div class="agree-bottom" v-if="showAgree">
      <van-checkbox v-model="checked" checked-color="#EC5B5B" icon-size="16px">我已阅读并同意《服务协议》</van-checkbox>
      <div class="agree-btn" @click="closeAgreePopup">我已阅读</div>
    </div>
     <div class="detail-item1 detail-vr" v-if="vrLink">
      <div class="item-title">
        <div class="title-left">VR看房</div>
      </div>
      <div class="vr-box">
       <iframe :src="vrLink"></iframe>
      </div>
    </div>
    <!-- 位置及周边 -->
    <div class="detail-item detail-map">
      <div class="item-title">
        <div class="title-left">位置及周边</div>
      </div>
      <div v-if="center.lat">
        <van-tabs v-model="active" background="#fff" title-active-color="#00D3C4" title-inactive-color="#444" color="#00D3C4" line-width="20px" line-height="2px" @click="goMapDetail">
          <van-tab v-for="(item, index) in tabsTitle" :title="item" :key="index">
            <baidu-map class="bm-view" ak="3m0AqdZmu1466T4OmwHbwhGSCBFEAGrM" :center="center" :dragging="dragging" :zoom="zoom" :min-zoom="1" :maxn-zoom="19" @touchmove="touchmove" @touchend="touchend">
              <bm-info-window :position="center" :title="item01.title" :show="showInfo" :width="220" :height="0" :offset="{width:0,height:25}" @close="showInfo = false">
                <p>{{item01.address}}</p>
              </bm-info-window>
              <bm-overlay pane="labelPane" style="position: relative;" @draw="draw">
                <div class="sample" @click="showInfo = true">
                  <img src="../../assets/images/dws.svg" alt="">
                </div>
              </bm-overlay>
            </baidu-map>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <!-- 在售户型 -->
    <div class="detail-item detail-type">
      <div class="item-title">
        <div class="title-left">在售户型</div>
        <div class="more" @click="goType">更多户型
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="type-box">
        <div class="type-item" v-for="(item, index) in typeList" :key="index" @click="goTypeBytypeId(item.id)">
          <img :src="item.pic" alt="" />
          <span>{{ item.title }}</span>
        </div>
      </div>
    </div>
    <!-- 楼盘动态 -->
    <div class="detail-item detail-dynamic">
      <div class="item-title">
        <div class="title-left">
          楼盘动态<span v-if="dynamicNum > 0">({{ dynamicNum }})</span>
        </div>
        <div class="more" @click="goDynamic">全部动态
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="dynamic-box">
        <div class="dynamic-time">{{ houseNotice.createTime != null?this.getTimes.timestampToTime(houseNotice.createTime):"" }}</div>
        <div class="dynamic-title">{{ houseNotice.title }}</div>
        <div class="dynamic-content" v-html="$xss(houseNotice.content)" style="height: 100px; overflow: hidden" ref="dynamic"></div>
      </div>
      <div class="show-introduce" v-if="!showDynamic" @click="openDynamic">
        展开
        <van-icon name="arrow-down" />
      </div>
      <div class="show-introduce" v-else @click="hideDynamic">
        收起
        <van-icon name="arrow-up" />
      </div>
    </div>
    <!-- 楼盘介绍 -->
    <div class="detail-item detail-introduce">
      <div class="item-title">
        <div class="title-left">楼盘介绍</div>
      </div>
      <div class="introduce-box" v-html="$xss(item01.content)" style="height: 100px; overflow: hidden" ref="introduce"></div>
      <div class="show-introduce" v-if="!showIntroduce" @click="openIntroduce">
        展开
        <van-icon name="arrow-down" />
      </div>
      <div class="show-introduce" v-else @click="hideIntroduce">
        收起
        <van-icon name="arrow-up" />
      </div>
    </div>
    <!-- 相关视频 -->
    <div class="detail-item detail-video">
      <div class="item-title">
        <div class="title-left">相关视频</div>
        <div class="more" @click="goVideo">更多视频
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="type-box">
        <div class="type-item" v-for="(item, index) in videoList" :key="index">
          <img :src="item.pic" alt="" @click="getVideo(item.id)" />
          <div class="video-text">
            <span>{{ item.title }}</span>
            <p>{{ item.playCount }}次播放</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 周边楼盘 -->
    <div class="detail-item detail-periphery">
      <div class="item-title">
        <div class="title-left">周边楼盘</div>
        <div class="more" @click="goBuildingList">更多
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="box-item" v-for="(item,index) in buildingList" :key="item.id" @click="goBuildingDetail(item.id)">
        <div class="box-content" v-if="index < 3">
          <building-item :item="item" :id="item.id" />
        </div>
      </div>
    </div>
    <!-- 免责内容 -->
    <div class="detail-annotation">
      <div class="annotation-content" v-html="$xss(annotation)"></div>
      <div class="copyright">
        <a :href="copyrightHref" target="_back">{{ copyright }}</a>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div class="detail-bottom" v-if="status == 1">
      <div class="bottom-btn" @click="orderhouse">预约看房</div>
    </div>
  </div>
</template>
<script>
import buildingItem from "../../components/BuildingItem.vue";
import { BaiduMap, BmInfoWindow, BmOverlay } from "vue-baidu-map";
import { isMobile } from "@/common/validate";
export default {
  name: "buildingDetail",
  data() {
    return {
      name: "",
      phone: "",
      vrLink:"",
      center: {},
      zoom: 13,
      active: 0,
      tabsTitle: ["学校", "医院", "购物", "银行", "公交", "地铁"],
      item01: {},
      swipeList: [],
      collect: false,
      labelList: [],
      typeList: [],
      dragging: false,
      videoList: [
        {
          imgUrl: require("../../assets/images/swiper01.jpg"),
          text: "视频01",
          num: 10,
        },
        {
          imgUrl: require("../../assets/images/swiper02.png"),
          text: "视频01视频01",
          num: 0,
        },
        {
          imgUrl: require("../../assets/images/swiper03.jpg"),
          text: "视频01",
          num: 0,
        },
        {
          imgUrl: require("../../assets/images/swiper03.jpg"),
          text: "视频01",
          num: 6,
        },
        {
          imgUrl: require("../../assets/images/swiper03.jpg"),
          text: "视频01",
          num: 5,
        },
      ],
      share: false,
      buildingList: [],
      updateTime: "",
      houseNotice: "",
      localUrl: "",
      showMsg: false,
      showIntroduce: false,
      showDynamic: false,
      dynamicNum: 1,
      id: null,
      item: null,
      copyright: "",
      copyrightHref: "",
      annotation: "",
      showPrice: false,
      showOpening: false,
      showAgree: false,
      checked: true,
      status: "",
      agree: "",
      showInfo: true,
    };
  },

  created() {
    // 获取传递过来的id
    this.id = this.$route.query.id;

  },
  mounted: function () {
    this.getHouseDetail();
  },
  methods: {
       nameFocus(value){
      this.name = value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g, "");
    },
    draw({ el, BMap, map }) {
      const { lng, lat } = this.center;
      const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat));
      el.style.left = pixel.x - 16 + "px";
      el.style.top = pixel.y + "px";
    },

    touchmove() {
      this.dragging = true;
    },
    touchend() {
      this.dragging = false;
    },
    // 是否收藏
    collectClick() {
      // this.collect = !this.collect;
      if (this.isLogin()) {
        const data = {
          //资讯id
          id: this.id,
          //是否收藏
          collect: this.collect,
        };
        this.$post("home/houseCollection", data).then((res) => {
          if (res.status == 200) {
            this.collect = !this.collect;
            this.$toast.success(res.msg);
          } else {
            this.$toast.fail(res.msg);
          }
        });
      }
    },

    getMessage(type) {
      if (!this.name) {
        this.$toast.fail("请填写联系人");
        return false;
      }
      if (!this.phone) {
        this.$toast.fail("请填写手机号码");
        return false;
      } else if (!isMobile(this.phone)) {
        this.$toast.fail("手机号码格式不正确");
        return false;
      }
      if (type == 0) {
        if (!this.checked) {
          this.$toast.fail("请阅读并同意协议");
          return false;
        }
      }
      const data = {
        id: this.id,
        name: this.name,
        phone: this.phone,
        type: type,
      };
      this.$post("user/getMessage", data, {
        toast: true,
        toastName: "提交中……",
      }).then((res) => {
        let page = this;
        if (res.status == 200) {
          var router = this.$toast.success({
            message: "楼盘信息将第一时间通知你",
            duration: 1500,
          });
          this.showPrice = false;
          this.showOpening = false;
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
   
    
    // 返回首页
    goBack() {
      if (sessionStorage.getItem("buildingType") == 'true') {
        this.$router.go(-1);
      } else {
        this.$router.push("/");
      }
    },
    // 跳转到相册
    goAlbum() {
      // this.$router.push({
      //   path: "/buildingAlbum",
      //   query: {
      //     id: this.id,
      //   },
      // });
    },
    goMapDetail(name, title) {
      this.$router.push({
        path: "/mapDetail",
        query: {
          active: title,
          id: this.item01.id,
          lat: this.center.lat,
          lng: this.center.lng,
        },
      });
    },
    orderhouse() {
      if (this.isLogin()) {
        this.$router.push({
          path: "/OrderHouse",
          query: {
            id: this.item01.id,
            title: this.item01.title,
            type: "new",
          },
        });
      }
    },
    // 跳转到在售户型
    goType() {
      this.$router.push({
        path: "/buildingType",
        query: {
          id: this.id,
          type: "house",
        },
      });
    },
    goTypeBytypeId(typeId) {
      this.$router.push({
        path: "/buildingType",
        query: {
          id: typeId,
          type: "houseType",
        },
      });
    },
    // 跳转到全部动态
    goDynamic() {
      this.$router.push({
        path: "/buildingDynamic",
        query: {
          id: this.id,
        },
      });
    },
    // 跳转到视频列表
    goVideo() {
      this.$router.push({
        path: "/buildingVideo",
        query: {
          id: this.id,
        },
      });
    },

    getVideo(id) {
      this.$router.push({
        path: "/videoDetail",
        query: {
          id: id,
          houseid: this.id,
        },
      });
    },
    // 跳转到楼盘列表
    goBuildingList() {
      this.$router.push({
        path: "/buildingList",
      });
    },
    //跳转到楼盘详情
    goBuildingDetail(houseId) {
      this.id = houseId;
      this.getHouseDetail();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      // location. reload()
    },
    // 跳转到贷款计算
    goCounter() {
      this.$router.push("/my/counter");
    },
    // 展开楼盘介绍
    openIntroduce() {
      this.showIntroduce = true;
      this.$refs.introduce.style.height = "auto";
    },
    // 收起楼盘介绍
    hideIntroduce() {
      this.showIntroduce = false;
      this.$refs.introduce.style.height = "100px";
    },
    // 展开楼盘介绍
    openDynamic() {
      this.showDynamic = true;
      this.$refs.dynamic.style.height = "auto";
    },
    // 收起楼盘介绍
    hideDynamic() {
      this.showDynamic = false;
      this.$refs.dynamic.style.height = "100px";
    },
    // 显示降价弹窗
    showPricePopup() {
      if (this.isLogin()) {
        this.showPrice = true;
      }
    },
    // 关闭降价弹窗
    closePricePopup() {
      console.log("有没有执行进来");
      this.showPrice = false;
    },
    // 显示开盘弹窗
    showOpeningPopup() {
      this.showOpening = true;
    },
    // 关闭开盘弹窗
    closeOpeningPopup() {
      this.showOpening = false;
    },
    // 显示开盘弹窗
    showAgreePopup() {
      this.showAgree = true;
    },
    // 关闭开盘弹窗
    closeAgreePopup() {
      this.showAgree = false;
    },

    getHouseDetail() {
      const data = {
        id: this.id,
      };
      this.$post("home/getHouseDetail", data).then((res) => {
        if (res.status == 200) {
          console.log(res.data.imgs, "图片");
          this.swipeList = res.data.imgs;
          this.labelList = res.data.tags;
          this.item01 = res.data.house;
          this.typeList = res.data.houseTypes;
          this.dynamicNum = res.data.houseNotices.length;
          this.houseNotice =
            res.data.houseNotices.length > 0 ? res.data.houseNotices[0] : "";
          this.buildingList = res.data.houses;
          this.copyright = res.data.codeMap.copyright;
          this.copyrightHref = res.data.codeMap.jump_link;
          this.annotation = res.data.codeMap.exemption_content;
          this.videoList = res.data.houseVideos;
          this.vrLink=res.data.house.vrLink;
          this.updateTime = res.data.updateTime;
          this.collect = res.data.collect;
          this.status = res.data.house.status;
          this.center = {
            lng: res.data.house.lng ? res.data.house.lng : "",
            lat: res.data.house.lat ? res.data.house.lat : "",
          };
          this.$getShareInfo(this.item01.title, this.swipeList[0]);
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
   
  },
  components: {
    buildingItem,
    BaiduMap,
    BmInfoWindow,
    BmOverlay,
  },

};
</script>
<style lang="less" scoped>
.introduce-box {
  font-size: 13px;
  overflow: hidden;
}

.bm-view {
  width: 100%;
  height: 210px;
}
.building-detail {
  padding-bottom: 60px;
  .detail-top {
    position: relative;
    .van-swipe-item {
      img {
        width: 100%;
        vertical-align: middle;
        height: 210px;
      }
    }
    .icon-box {
      position: absolute;
      display: flex;
      justify-content: space-between;
      top: 0;
      left: 0;
      right: 0;
      padding: 12px;
      .icon-item {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        .van-icon {
          color: #e5e4e2;
          font-size: 20px;
        }
      }
      .icon-right {
        display: flex;
        .icon-item {
          margin-left: 10px;
        }
      }
    }
    .browse {
      position: absolute;
      bottom: 12px;
      right: 12px;
      color: #fff;
      font-size: 15px;
    }
    .sell-short {
      width: 165px;
      height: 165px;
      position: absolute;
      right: 20px;
      z-index: 999;
      bottom: -70px;
      img {
        width: 165px;
        height: 165px;
        opacity: 0.5;
      }
    }
  }
  .detail-middle {
    background-color: #fff;
    padding: 0 15px;
    margin-bottom: 15px;
    .middle-name {
      padding: 10px 0;
      color: #333;
      font-size: 17px;
      border-bottom: 1px solid #eeeeee;
    }
    .middle-msg {
      padding: 10px 0;
      border-bottom: 1px solid #eeeeee;
      /deep/.van-grid-item {
        border-right: 1px solid #eeeeee;
        .van-grid-item__content {
          padding: 5px 0;
        }
        &:last-child {
          border-right: 0;
        }
      }

      p {
        color: #858585;
        font-size: 14px;
      }
      span {
        color: #e5513f;
        font-size: 16px;
        margin-top: 10px;
      }
      img {
        width: 16px;
        height: 16px;
        margin-bottom: 10px;
      }
    }
    .middle-active {
      padding: 10px 0;
      border-bottom: 1px solid #eeeeee;
      display: flex;
      align-items: center;
      .active-title {
        color: #e5513f;
        border: 1px solid #e5513f;
        padding: 2px 4px;
        border-radius: 3px;
        font-size: 12px;
        margin-right: 10px;
        flex-shrink: 0;
      }
      .active-content {
        font-size: 14px;
        color: #555;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .middle-content {
      padding: 10px 0;
      border-bottom: 1px solid #eeeeee;
      .middle-label {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        span {
          display: block;
          padding: 2px 5px;
          font-size: 13px;
          margin-right: 10px;
          margin-bottom: 10px;
          border-radius: 3px;
          &:nth-child(4n + 1) {
            color: #ff7a4d;
            background: #ffece8;
          }
          &:nth-child(4n + 2) {
            color: #00c482;
            background: #e1f5ed;
          }
          &:nth-child(4n + 3) {
            color: #ffa54c;
            background: #fdf1e0;
          }
          &:nth-child(4n + 4) {
            color: #00bfff;
            background: #e1ffff;
          }
        }
      }
      ul {
        li {
          height: 30px;
          display: flex;
          align-items: center;
          color: #858585;
          font-size: 15px;
          em {
            color: #555;
            display: block;
            width: 70px;
            flex-shrink: 0;
            font-size: 15px;
          }
          span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .show-msg {
        font-size: 14px;
        color: #888;
        display: flex;
        justify-content: center;
        margin-top: 10px;
        align-items: center;
      }
    }
    .middle-btn {
      display: flex;
      .btn-item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 56px;
        img {
          width: 19px;
          height: 19px;
          vertical-align: middle;
          margin-right: 5px;
        }
        span {
          color: #7f99ad;
          font-size: 15px;
        }
      }
    }
  }
  .detail-item {
    background-color: #fff;
    padding: 0 15px;
    margin-bottom: 15px;
    &.detail-map {
      padding: 0;
      .item-title {
        padding: 0 15px;
      }
      /deep/.BMap_bubble_title,
      /deep/.BMap_bubble_content {
        color: #000;
        font-size: 12px;
        text-overflow: ellipsis;
      }
      .sample {
        width: 32px;
        height: 32px;
        position: absolute;
      }
    }
    .item-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      .title-left {
        color: #666;
        font-size: 15px;
        padding-left: 15px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 4px;
          height: 14px;
          border-radius: 2px;
          background-color: #09d5c6;
        }
      }
      .more {
        color: #888;
        font-size: 14px;
      }
    }
  }
  .detail-item1 {
    background-color: #fff;
    margin-bottom: 15px;
   
      .item-title {
        padding: 0 15px;
      }
    
    .item-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      .title-left {
        color: #666;
        font-size: 15px;
        padding-left: 15px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 4px;
          height: 14px;
          border-radius: 2px;
          background-color: #09d5c6;
        }
      }
      
    }
  }
  .detail-vr{
    .vr-box{
      width:375px;
      height: 368px;
      display: flex;
      justify-items: center;
      align-content: center;
      iframe{
        width:375px;
      }
    }
  }
   .detail-type,
  .detail-video {
    .type-box {
      display: flex;
      align-items: center;
      width: 100%;
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }

      .type-item {
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 120px;
          height: 80px;
        }
        span {
          color: #333;
          font-size: 13px;
          display: block;
          width: 120px;
          line-height: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .video-text {
          height: 50px;
          p {
            color: #858585;
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }
  }
  .detail-introduce,
  .detail-dynamic {
    .show-introduce {
      font-size: 14px;
      color: #888;
      display: flex;
      justify-content: center;
      height: 40px;
      align-items: center;
    }
  }
  .detail-dynamic {
    .dynamic-box {
      padding-left: 15px;
      border-left: 1px solid #ddd;
      position: relative;
      &::before {
        content: "";
        width: 10px;
        height: 10px;
        background-color: #ddd;
        border-radius: 50%;
        position: absolute;
        left: -5px;
        top: 0;
      }
      .dynamic-time {
        font-size: 16px;
        color: #333;
        line-height: 1;
      }
      .dynamic-title {
        font-size: 18px;
        color: #333;
        margin: 10px 0;
      }
      .dynamic-content {
        font-size: 14px;
        color: #333;
      }
    }
  }
  .detail-periphery {
    .box-item {
      .box-content {
        padding: 10px 0;
        border-bottom: 1px solid #ddd;
      }
    }
  }
  .detail-annotation {
    padding: 15px;
    margin-top: -10px;
    .annotation-content {
      font-size: 14px;
      color: #aaa;
    }
    .copyright {
      text-align: center;

      a,
      a:hover,
      a:focus,
      a:active {
        font-size: 14px;
        color: #aaa;
        text-decoration: none;
      }
    }
  }
  .detail-bottom {
    padding: 5px 30px;
    border-top: 1px solid #ddd;
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 0;
    background-color: #fff;
    height: 60px;
    box-sizing: border-box;
    .bottom-btn {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 16px;
      background-color: #fca448;
      border-radius: 5px;
    }
  }
  /deep/.van-popup {
    width: 80%;
    overflow-y: unset;
    .price-box,
    .opening-box {
      padding: 20px 10px;
      position: relative;
      background: #f6f6f6;
      border-radius: 10px;
      text-align: center;
      .price-logo {
        width: 50px;
        height: 50px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -25px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }
      .price-title {
        margin: 5px 0 10px;
        color: #333;
        font-size: 20px;
      }
      .price-text {
        font-size: 14px;
        color: #666;
        margin-bottom: 10px;
      }
      .opening-text {
        line-height: 50px;
      }
      input {
        border: 0;
        outline: unset;
        background: #f6f6f6;
        width: 100%;
        padding: 10px 5px;
        height: 60px;
        box-sizing: border-box;
        border-bottom: 1px solid #ddd;
        font-size: 20px;
      }
      .van-checkbox {
        height: 50px;
        .van-checkbox__label {
          font-size: 14px;
          color: #878787;
          span {
            color: #788796;
            font-size: 15px;
          }
        }
      }
      .price-btn {
        display: flex;
        margin: 10px 0;
        div {
          flex: 1;
          margin: 0 10px;
          font-size: 17px;
          height: 34px;
          line-height: 34px;
          box-sizing: border-box;
          border-radius: 5px;
        }
        .btn-cancel {
          color: #555;
          background: #fff;
          border: 1px solid #ddd;
        }
        .btn-sure {
          color: #fff;
          background: #00d3c4;
        }
      }
    }
  }
  /deep/.van-popup.agree {
    width: 100%;
    overflow-y: auto;
    .agree-content {
      padding: 20px;
      width: 100%;
      background: #fff;
      box-sizing: border-box;
      min-height: 100vh;
      padding-bottom: 100px;
    }
  }
  .agree-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background-color: #fff;
    /deep/.van-checkbox {
      height: 44px;
      padding-left: 20px;
      .van-checkbox__label {
        font-size: 14px;
        color: #000;
      }
    }
    .agree-btn {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background-color: #00d3c4;
      font-size: 18px;
    }
  }
}
</style>